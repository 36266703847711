// import flatpickr from "flatpickr";
// import flatpickrLanguages from "flatpickr/dist/l10n";
import Modal from "./modules/frontv2/Modal";
// import SearchBar from "./modules/frontv2/SearchBar";
// import MobileSearch from "./modules/frontv2/MobileSearch";
import { initSearchBar } from "./modules/common/init-search-bar";
import Wish from "./modules/frontv2/Wish";
// import MobileMenu from "./modules/frontv2/MobileMenu";
import { init } from "@sentry/browser";
// import { getUserLanguage } from "./modules/utils";
import { initDrops } from "./modules/common/drop";
import { initScroll } from "./modules/scrolling";
import { initMobileMenu } from "./modules/common/mobile-menu";
import { initAutocomplete } from "./modules/frontv2/Autocomplete";
import { initSelects } from "./modules/common/selects";
import { initFormValidation } from "./modules/common/form-validation";
import { initMobileAroundMe } from "./modules/common/mobile-around-me-home";
import { initInfoCards } from "./modules/common/info-cards";
// import { initHeaderHome } from "./modules/frontv2/HeaderHome";
import { initMessages } from "./modules/common/message";
import { initCommon } from "./modules/common/common";
import { initScrollSearchBar } from "./modules/frontv2/scrollSearchBar";
import { initSeeMoreContent } from "./modules/frontv2/seeMoreContent";
import Referral from "./modules/frontv2/Referral";
import { initSwiper } from "./modules/common/init-swiper";
import { initHomeSeoBlock } from "./modules/frontv2/init-home-seo-block";
import { initCardsEffect } from "./modules/frontv2/CardsEffect";

document.addEventListener('DOMContentLoaded', () => {
  // Init Sentry JS
  if(document.body.dataset.env === 'prod') {
    // import('@sentry/browser')
    // .then((sentry) => {
      init({ dsn: 'https://b14c92e307604f4f8f454ebdd3eab1ef@sentry.io/1441102' });
    // });
  }

  // flatpickr.localize(flatpickrLanguages[getUserLanguage()]);

  //common stuff
  initCommon();

  //Dropdowns and dropups
  initDrops();

  //Flash messages
  initMessages();

  //Modal popups
  Modal.bindAllModals();

  //Header bar scroll logic
  initScroll();

  //Search bar logic (algolia)
  initAutocomplete();

  //Mobile menu
  initMobileMenu();

  //Init selects for langs (down the page)
  initSelects();

  //Init search logic
  let searchBarHome = document.querySelector('#wrapper-search-home');
  initSearchBar(searchBarHome);

  // Open the mobile search drawer by clicking on the home page search bar
  let overlay = searchBarHome.querySelector('#overlay-search-bar-home-page');
  overlay.addEventListener('click', () => {
    let searchBtn = document.querySelector('[data-drawer-tag="search-bar-mobile"]');
    if (searchBtn) searchBtn.click();
  });

  // new SearchBar('search-bar');
  // new SearchBar('second-search-bar');

  //Mobile logic
  // new MobileMenu();
  // new MobileSearch();

  //Init wish bind buttons
  Wish.bindWishButtons();

  //Init form validation + recaptcha
  initFormValidation();

  //Init mobile around me fixed footer
  initMobileAroundMe();

  //Init info cards (type COVID-19)
  initInfoCards();

  //init scroll search bar
  initScrollSearchBar();

  //see more logic for seo text
  initSeeMoreContent();

  //logic for referral code blocks
  new Referral();

  //logic to remove the around me bar
  const removeAroundMeBar = document.querySelector('#remove-around-me-bar');
  if(removeAroundMeBar){
    const wrapper = removeAroundMeBar.closest('.mobile-fixed-bar-arround-me');
    if(wrapper){
      removeAroundMeBar.addEventListener('click', () => {
        wrapper.remove();
      });
    }
  }

  //init any swiper on the page
  initSwiper();

  //init logic for the seo black block
  initHomeSeoBlock();

  //init the logic for the cards effect on top of the page
  initCardsEffect();
});
