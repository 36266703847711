import addLoadingStatusSearchOnMobile from "../frontv2/addLoadingStatusSearchOnMobile";
import { initSearchBar } from "./init-search-bar";

const initNav = () => {
    let nav = document.querySelector('#nav-bar');
    if (!nav) return;

    let searchBar = nav.querySelector('.search-bar');
    if (!searchBar) return;

    // Behavior on scroll
    if (nav.classList.contains('blackTextOnTransparentNavbar') || nav.classList.contains('whiteTextOnTransparentNavbar')) {
        // first load
        nav.classList.toggle('scrolled', window.scrollY > 0);
        // searchBar.classList.toggle('showed', window.scrollY > 0);

        // on scroll
        window.addEventListener('scroll', () => {
            nav.classList.toggle('scrolled', window.scrollY > 0);
            if(!searchBar.classList.contains('always-showed'))
                searchBar.classList.toggle('showed', window.scrollY > 0);

            searchBehaviorHomePage(searchBar);
        });
    }
    // ? Instead of else, we should use displaySearchBar var in the controller
    // ? to prevent the search bar from disappearing for a fraction of a second when the page loads
    // else {
    //     if (!searchBar.classList.contains('showed')) searchBar.classList.add('showed');
    // }

    // init search bar
    initSearchBar(nav);

    // init explore by region
    initExploreByRegion();

    // search bar on mobile
    initSearchBarOnMobile();
}

const searchBehaviorHomePage = (searchBar) => {
    if (document.body.dataset.routeCategory === "Home" && window.location.href === window.location.origin + "/") {
        let searchBarHome = document.querySelector('.search-bar-home');
        // When the position of searchBar is greater than the position of searchBarHome
        let conditionPositionSearchBar = searchBar.getBoundingClientRect().top >= searchBarHome.getBoundingClientRect().top;
        searchBar.classList.toggle('showed', conditionPositionSearchBar);
    }
}

const initExploreByRegion = () => {
    // Select drawer burger menu
    let burgerMenuDrawer = document.querySelector('[data-button-tag="burger-menu"]');
    if (!burgerMenuDrawer) return;

    // Select all country buttons
    let exploreByRegionBtns = burgerMenuDrawer.querySelectorAll('[data-drawer-tag=explore-by-region]');

    // Select explore by region drawer
    let exploreByRegionDrawer = document.querySelector('[data-button-tag="explore-by-region"]');
    if (!exploreByRegionDrawer || !exploreByRegionBtns) return;

    exploreByRegionBtns.forEach(exploreByRegionBtn => {
        // On click on a country
        exploreByRegionBtn.addEventListener('click', () => {
            let countryId = exploreByRegionBtn.dataset.countryId;
            let containerContent = exploreByRegionDrawer.querySelector('#content-explore-by-region');

            let regionsOfCountry = containerContent.querySelector(`[data-country-regions="${countryId}"]`);
            // show selected region
            regionsOfCountry.classList.remove('hidden');

            // hide other regions
            containerContent.querySelectorAll('[data-country-regions]').forEach(region => {
                if (region.dataset.countryRegions !== countryId) {
                    region.classList.add('hidden');
                }
            });
        })
    });
}

const initSearchBarOnMobile = () => {
    let searchBtn = document.querySelector('[data-drawer-tag="search-bar-mobile"]');
    let searchBar = document.querySelector('.search-bar.is-mobile input[id="mobile_search_bar"]');
    if (!searchBtn || !searchBar) return;

    // On click on search icon, focus on search bar
    searchBtn.addEventListener('click', () => {
        setTimeout(() => {
            searchBar.setSelectionRange(searchBar.value.length, searchBar.value.length);
            searchBar.focus();
        }, 300);
    })

    // On click on search btn on a virtual keyboard
    searchBar.addEventListener('search', () => {
        searchBar.blur();
        addLoadingStatusSearchOnMobile();
    });


    let clearSearch = document.querySelector('.clear-search');
    if (!clearSearch) return;

    // On input, show clear search icon
    searchBar.addEventListener('input', () => {
        if (searchBar.value.length > 0) {
            clearSearch.classList.remove('hidden');
        } else {
            clearSearch.classList.add('hidden');
        }
    });

    // On click on clear search icon, clear search bar
    clearSearch.addEventListener('click', () => {
        searchBar.value = '';

        let event = new Event('input');
        searchBar.dispatchEvent(event);
        
        clearSearch.classList.add('hidden');
        searchBar.focus();

        // Show dropdown suggestions host destination
        let mobileDropdownSuggestionsHostDestination = document.querySelector('.search-bar.is-mobile + .dropdown-suggestions-host-destination');
        mobileDropdownSuggestionsHostDestination?.classList.remove('hidden');
    });
}

export { initNav };
